/*
blue: 76C6FF
orange: FFB97A
purple: DB77FE
yellow: FFF276
red: FE7777
green: 9DF894
**/

html, body, #root {
  height: 100%;
}

.App {
  background: #76C6FF;
  color: #FFF;
  height: 100%;
}

.App .header {
  text-align: center;
  padding: 40px 0;
  font-weight: bold;
}

.App .dates {
  font-size: 20px
}

.App .title {
  font-size: 45px;
}

.App .accordion-item {
  margin-bottom: 10px;
}

.App .accordion-item button {
  background: #76C6FF;
  font-size: 20px;
  font-weight: bold;
}

.App .accordion-item .accordion-body p {
  font-size: 15px;
  font-weight: normal;
}

.App .accordion-item:nth-child(1) button,
.App .accordion-item:nth-child(1) {
  color: #DB77FE;
  border: 2px solid #DB77FE;
}

.App .accordion-item:nth-child(1) .accordion-body,
.App .accordion-item:nth-child(1) .accordion-body .list-group-item,
.App .accordion-item:nth-child(1) .accordion-body .list-group-item a {
  background: #DB77FE;
  color: #751198;
  font-weight: bold;
}

.App .accordion-item:nth-child(2) button,
.App .accordion-item:nth-child(2) {
  color: #9DF894;
  border: 2px solid #9DF894;
}

.App .accordion-item:nth-child(2) .accordion-body,
.App .accordion-item:nth-child(2) .accordion-body .list-group-item,
.App .accordion-item:nth-child(2) .accordion-body .list-group-item a {
  background: #9DF894;
  color: #37922E;
  font-weight: bold;
}

.App .accordion-item:nth-child(3) button,
.App .accordion-item:nth-child(3) {
  color: #FFF276;
  border: 2px solid #FFF276;
}

.App .accordion-item:nth-child(3) .accordion-body,
.App .accordion-item:nth-child(3) .accordion-body .list-group-item,
.App .accordion-item:nth-child(3) .accordion-body .list-group-item a {
  background: #FFF276;
  color: #998C10;
  font-weight: bold;
}

.App .accordion-item:nth-child(4) button,
.App .accordion-item:nth-child(4) {
  color: #FE7777;
  border: 2px solid #FE7777;
}

.App .accordion-item:nth-child(4) .accordion-body,
.App .accordion-item:nth-child(4) .accordion-body .list-group-item,
.App .accordion-item:nth-child(4) .accordion-body .list-group-item a {
  background: #FE7777;
  color: #981111;
  font-weight: bold;
}

.App .content-container {
  padding: 0 35px;
}


.App .footer {
  margin-top: 50px;
  text-align: center;
  font-weight: bold;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.App .footer p {
  margin: 0;
}
